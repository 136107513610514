/**
 * 
 */

import Player from '@vimeo/player'

/**
 * FlatVideos.
 */

export class FlatVideos {

    /**
     * Private variables.
     */

    private videos : Array<any>

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

        this.videos = []

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        this.configureFlatVideos()
        this.configureFlatVideos('.fullscreen-flatvideo-embed-container')

    }

    /**
     * configureFlatVideos.
     */

    configureFlatVideos(containerClass='.flatvideo-embed-container') {

        /**
         * 
         */

        // let videos : Array<any> = []

        /**
         * 
         */

        var videoContainers = document.querySelectorAll(containerClass)

        /**
         * 
         */

        videoContainers.forEach((container : HTMLElement) => {
    
            /**
             *
             */

            let skipGenericFlatVideoPluginSetup = ''
            
            /**
             *
             */

            if (container.hasAttribute('data-skip-generic-flat-video-plugin-setup')) {
                skipGenericFlatVideoPluginSetup = container.getAttribute('data-skip-generic-flat-video-plugin-setup')
            }            

            /**
             *
             */

            if (skipGenericFlatVideoPluginSetup == 'yes' || skipGenericFlatVideoPluginSetup == '1') {
                return
            }
            
            /**
             * 
             */

            let videoId  : string = ''
            let videoUrl : string = ''

            if (container.attributes.getNamedItem('data-video')) {
                videoId = container.attributes.getNamedItem('data-video').value
            }
            let autoplay : boolean = false
            let muted : boolean = false
            let controls : boolean = true
            if (container.attributes.getNamedItem('data-video-url')) {
                videoUrl = container.attributes.getNamedItem('data-video-url').value
            }

            /**
             *
             */

            let autoplayAttribute = container.attributes.getNamedItem('data-autoplay')
            let mutedAttribute = container.attributes.getNamedItem('data-muted')
            let controlsAttribute = container.attributes.getNamedItem('data-controls')

            /**
             *
             */

            if (autoplayAttribute && autoplayAttribute.value == 'yes') {
                autoplay = true
            }

            /**
             *
             */

            if (mutedAttribute && mutedAttribute.value == 'yes') {
                muted = true
            }
            
            /**
             *
             */

            if (controlsAttribute && controlsAttribute.value == 'no') {
                controls = false
            }

            /**
             * 
             */
    
            const options : any = {
                loop      : true,
                controls  : controls,
                muted     : muted,
                autopause : false // note: if this is set to true, the video will stop if another player starts.
            }

            /**
             *
             */

            if (videoId != '') {
                options.id = videoId
            }

            /**
             * If the video is unlisted, you'll need to use the full url with the `h` arg. Example:
             * https://player.vimeo.com/video/952280793?h=ba2794680e
             */

            if (videoUrl != '') {
                options.url = videoUrl
            }
    
            /**
             * 
             */

            let player = new Player(container, options)
            
            /**
             * 
             */

            let videoConf = {
                player    : player,
                id        : videoId,
                container : container
            }

            /**
             *
             */

            if (autoplay) {
                 
                /**
                 *
                 */

                setTimeout( () => {
                    player.play()
                }, 1000)

            }

            /**
             *
             */

            player.on('play', (data) => {
                
                /**
                 * 
                 */

                let playOneDataAttrName = 'data-play-one-at-a-time-group-name'

                /**
                 *
                 */

                if (container.hasAttribute(playOneDataAttrName)) {

                    /**
                     *
                     */

                    let groupName = container.getAttribute(playOneDataAttrName)

                    /**
                     *
                     */

                    this.videos.forEach((v) => {

                        /**
                         *
                         */

                        console.log('->')
                        console.log(v.container.getAttributeNames())

                        /**
                         *
                         */

                        if (v.container.hasAttribute(playOneDataAttrName)) {

                            /**
                             *
                             */

                            let _groupName = v.container.getAttribute(playOneDataAttrName)

                            console.log(groupName + ' -> ' + _groupName)

                            /**
                             *
                             */

                            if (v.player != player && groupName == _groupName) {

                                /**
                                 *
                                 */

                                console.log("STOP!@")

                                v.player.pause()

                            }

                        }

                    })

                }

            })


            // /**
            //  * 
            //  */

            // let playButton : HTMLElement = container.parentElement.querySelector('.video-player-trigger')

            // /**
            //  * 
            //  */

            // playButton.addEventListener('click', (e) => {

            //     /**
            //      * 
            //      */

            //     player.play()

            //     /**
            //      * 
            //      */

            //     container.parentElement.classList.add('video-playing')

            //     /**
            //      * 
            //      */

            //     e.preventDefault()                

            // })

            /**
             * 
             */

            this.videos.push(videoConf)

        })

    }

}
