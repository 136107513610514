/**
 * 
 */

import GLightbox from 'glightbox'
import $ from "jquery"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

/**
 * CamiliaCourtApartments.
 */

 export class CamiliaCourtApartments {

    /**
     * Private variables.
     */

    /**
     * 
     */

    private containerClass   : string
    private container        : HTMLElement
    private popupWrapper     : HTMLElement
    private floorplanGallery : any


    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        this.containerClass = '.common-panel-camelliacourtapartments-container'
        var container : HTMLElement = document.querySelector(this.containerClass)

        /**
         * 
         */

        if (! container) 
            return

        /**
         * 
         */

        this.container = container

        /** 
         *
         */

        this.setupGallery()
        this.configurePanel()
        this.configurePopup()

    }

    /**
     * setupGallery.
     */

    setupGallery() {

        /**
         *
         */

        this.floorplanGallery = GLightbox({
            zoomable: false
        })

    }

    /**
     * configurePopup.
     */

    configurePopup() {

        /**
         *
         */

        this.popupWrapper.querySelector('.back').addEventListener('click', (e) => {

            /**
             *
             */

            e.preventDefault()

            /**
             *
             */

            this.hidePopup()            

        })
        
    }

    /**
     * configurePanel.
     */

    configurePanel() {

        /**
         * 
         */

        let _this = this

        /**
         *
         */

        this.popupWrapper = document.querySelector( '.popup-wrapper.apartment-popup' )

        /**
         * 
         */

        let triggers : NodeListOf<HTMLElement> = this.container.querySelectorAll( '.block-apartment' )

        /**
         *
         */

        triggers.forEach(el => {

            /**
             *
             */

            el.addEventListener('click', (e) => {

                /**
                 *
                 */

                e.preventDefault()

                /**
                 *
                 */

                $.get( '/render-apartment/?apartment=' + el.getAttribute('data-key').toString(), function(data) {

                    /**
                     *
                     */

                    $(_this.popupWrapper).find('.popup-content').html( data )

                    /**
                     *
                     */

                    setTimeout(() => {

                        /**
                         *
                         */
                        
                        _this.showPopup()

                    }, 10)

                })

            })


        })

    }

    /**
     * configureFloorplanGallery.
     */

    configureFloorplanGallery() {

        /**
         *
         */

		const galleryTriggers = this.popupWrapper.querySelectorAll('.enlarge')

        /**
         *
         */

        let _galleryElements : NodeListOf<HTMLElement> = this.popupWrapper.querySelectorAll('.floorplan-gallery-image')
        let galleryImageElements : Array<any>          = []

        /**
         *
         */

        _galleryElements.forEach((element : HTMLImageElement) => {

            /**
             * 
             */

            let image = element.querySelector('img')
            let url   = image.src

            console.log(image, url)

            /**
             *
             */

            galleryImageElements.push({
                'href' : url,
                'type' : 'image',
                'title': ''
            })

        })

        /**
         *
         */

        galleryTriggers.forEach((trigger : HTMLElement) => {

            /**
             *
             */

            trigger.addEventListener('click', (event) => {

                /**
                 *
                 */

                event.preventDefault()

                /**
                 *
                 */

                this.floorplanGallery.setElements(galleryImageElements)

                /**
                 *
                 */

                this.floorplanGallery.open()

            })

        })

    }

    /**
     * showPopup.
     */

    showPopup() {

        /**
         *
         */

        this.popupWrapper.scrollTo({
            top: 0
        });

        /**
         * 
         */

        if (window.innerWidth >= 451 ) {
            const targetElement = this.popupWrapper.querySelector('.popup-content')
            disableBodyScroll(targetElement);
        }

        /**
         *
         */

        (<any>window).expandElementsToBrowserWidth.resizeAllElements()

        /**
         *
         */

        this.configureFloorplanGallery()

        /**
         *
         */

        this.popupWrapper.classList.add( 'show' )       

        /**
         *
         */

        document.querySelector('body').classList.add('disable-overflow')
        
    }

    /**
     * hidePopup.
     */

    hidePopup() {

        /**
         * 
         */

        const targetElement = this.popupWrapper.querySelector('.popup-content')
        enableBodyScroll(targetElement)

        /**
         *
         */

        this.popupWrapper.classList.remove( 'show' )
        
        /**
         *
         */

        document.querySelector('body').classList.remove('disable-overflow')
        
    }

}
