/**
 * 
 */

// import Panzoom, { PanzoomEvent, PanzoomObject } from '@panzoom/panzoom'

import Leaflet, { map, latLng, tileLayer, MapOptions, CRS, imageOverlay, LatLngBounds, PointExpression, divIcon } from 'leaflet'

// Make sure you add the stylesheet to the page:
// <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin=""/>

/**
 * 
 */

interface AddMarkerToMapOptions {
    imageUrl              : string,
    title                 : string,
    description           : string,
    markerX?              : number,
    markerY?              : number,
    markerIconUrl         : string,
    iconSize              : PointExpression,
    iconAnchor            : PointExpression,
    popupAnchor           : PointExpression,
    markerXPercent        : number,
    markerYPercent        : number,
    color                 : string,
    popupVerticalPosition : string
}

/**
 * InteractiveSiteplanLeaflet.
 */

 export class InteractiveSiteplanLeaflet {

    /**
     * Private variables.
     */

    /**
     * 
     */

    private containerClass : string
    private container : HTMLElement
    // private panzoom : PanzoomObject
    private hasInteracted : boolean
    private isZooming : boolean

    private map : Leaflet.Map
    private boundWidth : number
    private boundHeight : number

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         *
         */

        this.isZooming      = false
        this.hasInteracted  = false

        /**
         * 
         */

        this.containerClass = '.common-panel-interactivesiteplan-container'
        var container : HTMLElement = document.querySelector(this.containerClass)

        /**
         * 
         */

        if (! container) 
            return

        /**
         * 
         */

        this.container = container

        /** 
         *
         */

        this.configureLeaflet()

    }

    /**
     * hideInstructions.
     */

    hideInstructions() {

        /**
         *
         */

        if (this.hasInteracted == false) {
            this.hasInteracted = true
        } else {
            return
        }
        /**
         * 
         */

        let insructionsBlock = document.querySelector('.interaction-message-wrapper')
        
        /**
         *
         */

        insructionsBlock.classList.add('hidden')

    }

    /**
     * configureLeaflet.
     */

    configureLeaflet() {

        /**
         * 
         */

        const options: MapOptions = {
            zoom               : 2,
            crs                : CRS.Simple,
            minZoom            : 4.3,
            maxZoom            : 8,
            maxBoundsViscosity : 1.0,
            scrollWheelZoom    : false
        }      

        // 3646, 2749

        /**
         * 
         */

        const mymap = map('interactive-siteplan', options)

        /**
         *
         */

        this.boundWidth  = 59
        this.boundHeight = 36

        /**
         * 
         */

        let bounds : LatLngBounds = new LatLngBounds([[0,0], [this.boundHeight, this.boundWidth]])

        /**
         * 
         */

        var image = imageOverlay('/assets/images/vision/interactive-siteplan.jpg', bounds).addTo(mymap)

        /**
         *
         */

        mymap.setMaxBounds(bounds)

        /**
         *
         */

        mymap.zoomControl.setPosition('topright')

        /**
         *
         */

        mymap.fitBounds(bounds)

        /**
         *
         */

        mymap.setZoom(4.3, {
            animate: false
        })

        /**
         *
         */

        mymap.on('click', () => {
            this.hideInstructions()
        });

        /**
         *
         */

        mymap.on('movestart', () => {
            this.hideInstructions()
        });

        /**
         *
         */

        mymap.on('zoomstart', () => {
            this.hideInstructions()
        });

        /**
         *
         */

        (<any>window).getZoomLevel = () => {
            console.log( mymap.getZoom())
        } 

        /**
         *
         */

        mymap.on('popupopen', () => {

            console.log('popupclose')

            let popups = document.querySelectorAll('.leaflet-popup')


            // popups.item(0).classList.remove('popup-position-below')
// 
            // document.querySelector('.leaflet-popup').classList.remove('popup-position-below')


        })

        /**
         *
         */

        this.map = mymap

        /**
         * Example of marker format:
         */

        // this.addMarkerToMap({
        //     title         : 'Location Title',
        //     description   : 'Lorem ipsum dolar sit amet dolar lorem.',
        //     markerIconUrl : '/assets/images/interactive-siteplan/pins/village-centre.png',
        //     markerXPercent : 10,
        //     markerYPercent : 10,
        //     imageUrl      : '/wp-content/uploads/2024/05/pexels-apasaric-2707756-scaled.jpg',
        //     iconSize      : [60, 60],
        //     iconAnchor    : [30, 30],
        //     popupAnchor   : [0, -25]
        // })

        /**
         *
         */

        this.addMarkersToMap()

    }

    /**
     * addMarkersToMap.
     */

    addMarkersToMap() {

        /**
         * 
         */

        let markers : Array<any> = (<any>window).interactiveSiteplanPinsData

        /**
         *
         */

        markers.forEach(markerOptions => {

            /**
             *
             */

            this.addMarkerToMap(markerOptions)

        })

    }

    /**
     * addMarkerToMap.
     */

    addMarkerToMap(options : AddMarkerToMapOptions) {

        /**
         *
         */

        var icon = Leaflet.icon({
            iconUrl     : options.markerIconUrl,
            iconSize    : options.iconSize,     // size of the icon
            iconAnchor  : options.iconAnchor,  // point of the icon which will correspond to marker's location
            popupAnchor : options.popupAnchor   // point from which the popup should open relative to the iconAnchor
        });

        /**
         * 
         */

        var sol

        /**
         *
         */

        if (options.markerX && options.markerY) {

            /**
             *
             */

            sol = Leaflet.latLng([ options.markerY, options.markerX ])

        } else if (options.markerXPercent && options.markerYPercent) {

            /**
             *
             */

            sol = Leaflet.latLng([ 
                this.boundHeight * (options.markerYPercent / 100), 
                this.boundWidth  * (options.markerXPercent / 100), 
            ])

        }        

        /**
         *
         */

        let marker = Leaflet.marker(sol, {
            icon: icon
        })

        /**
         * 
         */

        let autoPan : boolean = true

        /**
         * 
         */

        if ( options.popupVerticalPosition == 'below' ) {
            autoPan = false
        }

        /**
         *
         */

        marker.bindPopup(`
        <div class="image-container" style="background-image: url(${ options.imageUrl });">
        </div>
        <div class="text-inset" style="background-color: ${ options.color } ;">
            <p class="title">${ options.title }</p>
            <p class="description">${ options.description }</p>
        </div>`,
        {
            maxWidth: 360,
            autoPan: autoPan,
            className: 'color-' + (options.color).replace('#', '') + ' popup-position-' + options.popupVerticalPosition
        })

        /**
         *
         */

        marker.addTo(this.map).on('click', () => {
            this.hideInstructions()
        })

    }

}
