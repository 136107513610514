/**
 * 
 */

import { SwiperSlider } from '../elements/swiperslider'

/**
 * TwoColumnTextAndImageCarousel.
 */

 export class TwoColumnTextAndImageCarousel {

    /**
     * Private variables.
     */

    /**
     * 
     */

    private containerClass : string
    private containers : Array<HTMLElement>
    private swipderSliders : Array<any>

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

        this.containers     = []
        this.swipderSliders = []

    }

    /**
     * start.
     */

    start(): void {

        /**
         *
         */

        console.log( 'Two Column Text and Image Carousel' )

        /**
         * 
         */

        this.containerClass = '.common-panel-twocolumntextandimagecarousel-container'
        var containers : NodeListOf<HTMLElement> = document.querySelectorAll(this.containerClass)

        /**
         *
         */

        containers.forEach((container : HTMLElement) => {

            /**
             *
             */

            this.containers.push( container )

        })

        /**
         * 
         */

        if (this.containerClass.length == 0) 
            return

        /** 
         *
         */

        this.configureContainers()

    }

    /**
     * configureContainers.
     */

    configureContainers() {

        /**
         *
         */

        this.containers.forEach((container : HTMLElement) => {

            /**
             *
             */

            let random = Math.floor(Math.random() * 1000) + 1; 

            /**
             *
             */

            /**
             *
             */

            container.querySelector( '.carousel ' ).classList.add( 'carousel-' + random )

            /**
             *
             */

            let swiperSlider = new SwiperSlider(' .carousel-' + random , {

                /**
                 *
                 */

                slidesPerView : 1,

                /**
                 * 
                 */

                spaceBetween: 0,
                 
                /**
                 *
                 */

                autoHeight: true,

                /**
                 *
                 */

                loop: true,

                /**
                 *
                 */

                // autoplay: {
                //     delay                : 5000,
                //     disableOnInteraction : true
                // },
                
            })
             
            /**
             *
             */

            this.swipderSliders.push( swiperSlider )

        })       

    }

}
