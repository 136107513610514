/**
 * Utils.
 */

import { Ready } from './utils/ready'

/**
 * Components.
 */

/**
 * Layouts.
 */

import { NavBar } from './layouts/common/nav-bar'

/**
 * Panels.
 */

import { InteractiveSiteplanLeaflet }       from './panels/interactive-siteplan-leaflet'
import { InteractiveMapLeaflet }            from './panels/interactive-map-leaflet'
import { TwoColumnTextAndImageCarousel }    from './panels/two-column-text-and-image-carousel'
import { HousesAtPinewoodGreenEast }        from './panels/houses-at-pinewood-green-east'
import { CamiliaCourtApartments }           from './panels/camilia-court-apartments'

/**
 * Effects.
 */

import { SVGReplace }                   from './effects/svg-replace'
import { FlatVideos }                   from './effects/flat-videos'
import { ExpandElementsToBrowserWidth } from './effects/expand-elements-to-browser-width'
import { EnsureElementsAreSameHeight }  from './effects/ensure-elements-are-same-height'

/**
 * Components.
 */

/**
 * Elements.
 */

/**
 * React
 */

/**
 * App.
 */

export class App {

    /**
     * Components.
     */

    /**
     * Layouts.
     */

    private navBar : NavBar

    /**
     * Panels.
     */

    private interactiveSiteplanLeaflet : InteractiveSiteplanLeaflet
    private interactiveMapLeaflet : InteractiveMapLeaflet
    private twoColumnTextAndImageCarousel : TwoColumnTextAndImageCarousel
    private housesAtPinewoodGreenEast : HousesAtPinewoodGreenEast
    private camiliaCourtApartments : CamiliaCourtApartments

    /**
     * Effects.
     */

    private svgReplace                   : SVGReplace
    private flatVideos                   : FlatVideos
    private expandElementsToBrowserWidth : ExpandElementsToBrowserWidth
    private ensureElementsAreSameHeight  : EnsureElementsAreSameHeight

    /**
     * React.
     */

    /**
     * Elements.
     */

    /**
     * Post types.
     */

    /**
     * constructor.
     */

    constructor() {
        
        /**
         *
         */

        /**
         * Layouts.
         */

        this.navBar = new NavBar()

        /**
         * Panels.
         */
        
        this.interactiveSiteplanLeaflet     = new InteractiveSiteplanLeaflet()
        this.interactiveMapLeaflet          = new InteractiveMapLeaflet()
        this.twoColumnTextAndImageCarousel  = new TwoColumnTextAndImageCarousel()
        this.housesAtPinewoodGreenEast      = new HousesAtPinewoodGreenEast()
        this.camiliaCourtApartments         = new CamiliaCourtApartments()       

        /**
         * Effects.
         */

        this.svgReplace                   = new SVGReplace()
        this.flatVideos                   = new FlatVideos()
        this.expandElementsToBrowserWidth = new ExpandElementsToBrowserWidth()
        this.ensureElementsAreSameHeight  = new EnsureElementsAreSameHeight()

        /**
         * Post types.
         */

        /**
         * React.
         */

        /**
         * Elements.
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         * When document is ready.
         */

        new Ready(() => {

            /**
             * Components.
             */

            this.navBar.start()

            /**
             * Start layouts.
             */

            /**
             * Start panels.
             */

            this.interactiveSiteplanLeaflet.start()
            this.interactiveMapLeaflet.start()
            this.twoColumnTextAndImageCarousel.start()
            this.housesAtPinewoodGreenEast.start()
            this.camiliaCourtApartments.start()

            /**
             * Start effects.
             */

            this.svgReplace.start()
            this.flatVideos.start()
            this.expandElementsToBrowserWidth.start()
            this.ensureElementsAreSameHeight.start()

            /**
             * Post types.
             */

            /**
             * React.
             */

            /**
             * Elements.
             */

        })

    }

}
