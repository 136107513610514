/**
 * ExpandElementsToBrowserWidth.
 */

export class ExpandElementsToBrowserWidth {

    /**
     * Private variables.
     */

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         * 
         */

        this.expandElementsToBrowserWidth()
        this.listenToWindowResize();

        /**
         *
         */

        (<any>window).expandElementsToBrowserWidth = this

    }

    /**
     * expandElementToBrowserWidth.
     */

    expandElementsToBrowserWidth() {

        /**
         *
         */

        this.resizeAllElements()
        
    }

    /**
     * resizeAllElements.
     */

    resizeAllElements() {

        /**
         * 
         */

        let videos : Array<any> = []

        /**
         * 
         */

        var targetElements = document.querySelectorAll('[data-expand-to-browser-width')

        /**
         * 
         */

        targetElements.forEach((element : HTMLElement) => {
                
            /**
             *
             */

            this.resizeElement( element )


        })

    }
    
    /**
     * listenToWindowResize.
     */
    
    listenToWindowResize() {
        
        /**
         *
         */

        window.addEventListener("resize", (event) => {

            console.log( "resize" )

            /**
             *
             */

            this.resizeAllElements()

        })

    }
    
    /**
     * resizeElement.
     */
    
    resizeElement(element : HTMLElement) {
        
        /**
         * 
         */
    
        let expandLeft : boolean  = undefined
        let expandRight : boolean = undefined
    
        /**
         *
         */

        let maxExpandableWidth : number = undefined

        /**
         *
         */
    
        if (element.hasAttribute('data-expand-left')) {
            expandLeft = true
        } else if (element.hasAttribute('data-expand-right')) {
            expandRight = true
        } else {
            expandLeft  = false
            expandRight = true
        }

        /**
         * 
         */

        if (element.hasAttribute('data-max-expandable-width')) {
            maxExpandableWidth = parseInt( element.getAttribute('data-max-expandable-width').toString() )
        }

        /**
         *
         */
    
        var rect        = element.getBoundingClientRect()
        let elementLeft = rect.left
    
        /**
         *
         */
    
        let browserWidth : number = this.getBrowserWidth()
        
        /**
         *
         */  
        
        if (expandRight) {

            /**
             * Important: The browser must not be allowed to have horizontal scrollbars,
             * otherwise it will take the scrollbar width and not allow the element to scale
             * down. Adding "overflow: hidden" on some wrapping element will do the trick.
             * Alternatively, modify the "getBrowserWidth" method to ignore scrollWidths.
             */
    
            let newWidth = (browserWidth - elementLeft)

            /**
             * 
             */

            if (maxExpandableWidth) {
                if (newWidth > maxExpandableWidth) {
                    newWidth = maxExpandableWidth
                }
            }

            /**
             * 
             */

            element.style['width'] = newWidth + "px"
            
        } else {

            /**
             *
             */

            let parentElement = element.parentElement

            /**
             * 
             */

            var parentElementRect   = element.getBoundingClientRect()
            let parentElementRight  = parentElementRect.right
            let parentElementLeft   = parentElementRect.left

            /**
             *
             */

            parentElement.style['display']        = 'flex'
            parentElement.style['flexDirection']  = 'column'
            parentElement.style['alignItems']     = 'flex-end'
            parentElement.style['height']         = '100%'
            
            /**
             * 
             */

            let newWidth = parentElementRight

            /**
             * 
             */

            if (maxExpandableWidth) {
                if (newWidth > maxExpandableWidth) {
                    newWidth = maxExpandableWidth
                }
            }

            /**
             * 
             */

            element.style['width'] = newWidth + 'px'

        }

    }

    /**
     * getBrowserWidth.
     */

    getBrowserWidth() {

        /**
         *
         */

        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );

    }

}
