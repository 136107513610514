/**
 * EnsureElementsAreSameHeight.
 */

export class EnsureElementsAreSameHeight {

    /**
     * Private variables.
     */

    private groupNames    : Array<any>
    private elementGroups : any

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {
        
        /**
         * 
         */

        this.groupNames    = []
        this.elementGroups = {}

        /**
         *
         */

        this.ensureElementsAreSameHeight()
        this.listenToWindowResize();

    }

    /**
     * ensureElementsAreSameHeight.
     */

    ensureElementsAreSameHeight() {

        /**
         * 
         */

        var targetElements = document.querySelectorAll('[data-ensure-element-same-height')

        /**
         * 
         */

        targetElements.forEach((element : HTMLElement) => {

            /**
             * 
             */

            if (element.hasAttribute('data-same-height-group-name')) {

                /**
                 * 
                 */

                let groupName = element.getAttribute('data-same-height-group-name').toString()

                /**
                 *
                 */

                if (! this.groupNames.includes(groupName)) {
                    this.groupNames.push( groupName )
                    this.elementGroups[groupName] = new Array()
                }

                /**
                 *
                 */

                (<Array<any>>this.elementGroups[groupName]).push( element )

                /**
                 *
                 */

                element.style['height'] = 'auto'

            }

        })

        /**
         *
         */

        this.groupNames.forEach( groupName => {

            /**
             * 
             */

            let elementsInGroup = (<Array<any>>this.elementGroups[groupName])

            /**
             *
             */

            let elementsGroupedByTop : any        = {}
            let elementGroupTops     : Array<any> = []

            /**
             * 
             */

            elementsInGroup.forEach((element) => {

                /**
                 * 
                 */

                var rect = element.getBoundingClientRect()    
                var top  = rect.top

                /**
                 *
                 */

                if (! elementGroupTops.includes(top)) {
                    elementGroupTops.push( top )
                    elementsGroupedByTop[top] = new Array()
                }

                /**
                 *
                 */

                (<Array<any>>elementsGroupedByTop[top]).push( element )

            })

            /**
             * 
             */

            // let anElementIsOverflowing : boolean = false

            /**
             * 
             */

            elementGroupTops.forEach((elementGroupTop) => {
                 
                /**
                 *
                 */

                let largestHeight : number = 0

                /**
                 * 
                 */

                let elementsInGroupByTop = (<Array<any>>elementsGroupedByTop[elementGroupTop])

                /**
                 * 
                 */

                elementsInGroupByTop.forEach((element) => {

                    /**
                     * 
                     */

                    var rect   = element.getBoundingClientRect()    
                    var height = rect.height

                    /**
                     *
                     */

                    // if (element.scrollHeight != Math.max(element.offsetHeight, element.clientHeight)) {
                    //     anElementIsOverflowing = true
                    // }

                    /**
                     *
                     */

                    if (height > largestHeight) {
                        largestHeight = height
                    }

                })

                /**
                 * 
                 */

                elementsInGroupByTop.forEach((element) => {

                    /**
                     *
                     */

                    element.style['height'] = largestHeight + 'px'

                    /**
                     *
                     */

                    // if (anElementIsOverflowing) {
                    //     element.style['height'] = 'auto';
                    // }

                })

            })

        })

    }
    
    /**
     * listenToWindowResize.
     */
    
    listenToWindowResize() {
        
        /**
         *
         */

        window.addEventListener("resize", (event) => {

            /**
             *
             */

            this.ensureElementsAreSameHeight()

        })

    }

}
