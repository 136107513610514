/**
 * 
 */

// import Panzoom, { PanzoomEvent, PanzoomObject } from '@panzoom/panzoom'

import Leaflet, { map, latLng, tileLayer, MapOptions, CRS, imageOverlay, LatLngBounds, PointExpression } from 'leaflet'

// Make sure you add the stylesheet to the page:
// <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin=""/>

/**
 * 
 */

interface AddMarkerToMapOptions {
    imageUrl       : string,
    title          : string,
    description    : string,
    markerX?       : number,
    markerY?       : number,
    markerIconUrl  : string,
    iconSize       : PointExpression,
    iconAnchor     : PointExpression,
    popupAnchor    : PointExpression,
    markerXPercent : number,
    markerYPercent : number,
    color          : string,
    number         : string
}

/**
 * InteractiveMapLeaflet.
 */

 export class InteractiveMapLeaflet {

    /**
     * Private variables.
     */

    /**
     * 
     */

    private containerClass : string
    private container : HTMLElement
    // private panzoom : PanzoomObject
    private hasInteracted : boolean
    private isZooming : boolean

    private map : Leaflet.Map
    private boundWidth : number
    private boundHeight : number

    private markers : Array<any>

    /**
     * constructor.
     */

    constructor() {

        /**
         * 
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         *
         */

        this.isZooming     = false
        this.hasInteracted = false
        this.markers       = []

        /**
         * 
         */

        this.containerClass = '.common-panel-interactivemap-container'
        var container : HTMLElement = document.querySelector(this.containerClass)

        /**
         * 
         */

        if (! container) 
            return

        /**
         * 
         */

        this.container = container

        /** 
         *
         */

        this.configureLeaflet()

        /**
         *
         */

        // setInterval(() => {
        //     this.markers.forEach( m => {
        //         console.log( m.getLatLng() )
        //     })
        // }, 5000)
    
        /**
         * 
         */

        let pinsDataArray : Array<any> = []

        /**
         *
         */

        if (document.querySelector('.update-pins')) {

            /**
             *
             */

            document.querySelector('.update-pins').addEventListener('click', (event) => {
    
                /**
                 *
                 */

                pinsDataArray = []
    
                /**
                 *
                 */
    
                this.markers.forEach( m => {
    
                    /**
                     * 
                     */
    
                    let ll = m.marker.getLatLng()
                    let num = m.number
                    let title = m.title
                    
                    // console.log(num + " : " + title + " -- " + ll.lat + ' / ' + ll.lng + '....' + (( ll.lng / this.boundWidth ) * 100) + '% - ' + (100 - (( ll.lat / this.boundHeight ) * 100)) )
    
                    /**
                     *
                     */
    
                    pinsDataArray.push({
                        number: num,
                        x: Math.round(( ll.lng / this.boundWidth ) * 100),
                        y: Math.round((100 - (( ll.lat / this.boundHeight ) * 100)))
                    })
    
                    /**
                     *
                     */
    
    
    
                })
    
                /**
                 *
                 */

                console.log(pinsDataArray)
    
                /**
                 *
                 */

                jQuery.ajax({
                    type: "POST",
                    url: '/interactive-map-update-pins/',
                    data: {
                        data: pinsDataArray
                    },
                    success: () => {
    
                    }
                })
    
                /**
                 *
                 */

                event.preventDefault()

            })

        }

    }

    /**
     * hideInstructions.
     */

    hideInstructions() {

        /**
         *
         */

        if (this.hasInteracted == false) {
            this.hasInteracted = true
        } else {
            return
        }
        /**
         * 
         */

        let insructionsBlock = document.querySelector('.interaction-message-wrapper')
        
        /**
         *
         */

        insructionsBlock.classList.add('hidden')

    }

    /**
     * configureLeaflet.
     */

    configureLeaflet() {

        /**
         * 
         */

        const options: MapOptions = {
            zoom               : 2.2,
            crs                : CRS.Simple,
            minZoom            : 2.2,
            maxZoom            : 3,
            maxBoundsViscosity : 1.0,
            scrollWheelZoom    : false,
            zoomSnap           : 0.1
        }      

        // 3646, 2749

        /**
         * 
         */

        const mymap = map('interactive-map', options)

        /**
         *
         */

        this.boundWidth  = 265.2
        this.boundHeight = 175.0

        /**
         * 
         */

        let bounds : LatLngBounds = new LatLngBounds([[0,0], [this.boundHeight, this.boundWidth]])

        /**
         * 
         */

        var image = imageOverlay('/assets/images/interactive-map/map.jpg', bounds).addTo(mymap)

        /**
         *
         */

        // mymap.setMaxBounds(bounds)

        /**
         *
         */

        mymap.zoomControl.setPosition('topright')

        /**
         *
         */

        mymap.fitBounds(bounds)

        /**
         *
         */

        mymap.setZoom(2.2, {
            animate: false
        })
        
        /**
         *
         */

        mymap.on('click', () => {
            this.hideInstructions()
        });

        /**
         *
         */

        mymap.on('movestart', () => {
            this.hideInstructions()
        });

        /**
         *
         */

        mymap.on('zoomstart', () => {
            this.hideInstructions()
        });

        /**
         *
         */

        (<any>window).getZoomLevel = () => {
            console.log( mymap.getZoom())
        } 

        /**
         *
         */

        this.map = mymap

        /**
         * Example of marker format:
         */

        // this.addMarkerToMap({
        //     title         : 'Location Title',
        //     description   : 'Lorem ipsum dolar sit amet dolar lorem.',
        //     markerIconUrl : '/assets/images/interactive-siteplan/pins/village-centre.png',
        //     markerXPercent : 10,
        //     markerYPercent : 10,
        //     imageUrl      : '/wp-content/uploads/2024/05/pexels-apasaric-2707756-scaled.jpg',
        //     iconSize      : [60, 60],
        //     iconAnchor    : [30, 30],
        //     popupAnchor   : [0, -25]
        // })

        /**
         *
         */

        this.addMarkersToMap()

    }

    /**
     * addMarkersToMap.
     */

    addMarkersToMap() {

        /**
         * 
         */

        let markers : Array<any> = (<any>window).interactiveSiteplanPinsData

        /**
         *
         */

        markers.forEach(markerOptions => {

            /**
             *
             */

            this.addMarkerToMap(markerOptions)

        })

    }

    /**
     * addMarkerToMap.
     */

    addMarkerToMap(options : AddMarkerToMapOptions) {

        /**
         *
         */

        var icon = Leaflet.icon({
            iconUrl     : options.markerIconUrl,
            iconSize    : options.iconSize,     // size of the icon
            iconAnchor  : options.iconAnchor,  // point of the icon which will correspond to marker's location
            popupAnchor : options.popupAnchor   // point from which the popup should open relative to the iconAnchor
        });

        /**
         * 
         */

        var sol

        /**
         *
         */

        if (options.markerX && options.markerY) {

            /**
             *
             */

            sol = Leaflet.latLng([ options.markerY, options.markerX ])

        } else if (options.markerXPercent && options.markerYPercent) {

            /**
             *
             */

            let markX = options.markerXPercent
            let markY = options.markerYPercent

            /**
             *
             */

            // markX = 100 - markX
            // markY = 100 - markY

            // markX = markX
            // markY = markY

            /**
             * 
             */

            // let x = (this.boundWidth  * (markY / 100))
            // let y = (this.boundHeight * (markX / 100))

            // markX = (markX / 100) * this.boundWidth
            // markY = (markY / 100) * this.boundHeight

            /**
             * 
             */

            // let x = (this.boundWidth  * markX)
            // let y = (this.boundHeight * markY)

            // let x = markX
            // let y = markY

            markX = 100 - markX

            let x = ( markX / 100 ) * this.boundWidth
            let y = ( markY / 100 ) * this.boundHeight

            sol = Leaflet.latLng([ 
                y, 
                x, 
            ])

        }        

        /**
         *
         */

        let isDraggable : boolean = false

        /**
         *
         */

        if ((<any>window).mapMakeEditable) {
            isDraggable = true
        }

        /**
         *
         */

        let marker = Leaflet.marker(sol, {
            icon: icon,
            draggable: isDraggable
        })

        /**
         *
         */

        marker.bindPopup(`
        <div class="text-inset" style="background-color: ${ options.color } ;">
            <p class="title">${options.number}: ${ options.title }</p>
        </div>`,
        {
            maxWidth: 360,
            className: 'color-' + (options.color).replace('#', '')
        })

        // console.log( marker.getElement() )
        // let v : any = marker.valueOf()
        // console.log(v._icon)
        // v._icon.style.backgroundColor = 'green'

        /**
         *
         */

        marker.addTo(this.map).on('click', () => {
            this.hideInstructions()
        })

        /**
         *
         */

        this.markers.push({
            marker  : marker,
            title   : options.title,
            number  : options.number
        })

    }

}
