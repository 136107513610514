/**
 * 
 */

import { merge } from 'lodash'

/**
 *
 */

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
import Swiper, { Pagination, EffectFade, Autoplay } from 'swiper'
Swiper.use([Pagination, EffectFade, Autoplay])

/**
 * SwiperSlider.
 */

 export class SwiperSlider {

    /**
     * Private variables.
     */

    private sliderClass : string
    private swiperContainerElement : HTMLElement
    private instance : Swiper
    private suppliedSliderOptions : Object

    /**
     * constructor.
     */

    constructor(sliderClass : string, sliderOptions : Object) {

        /**
         * 
         */

        this.sliderClass            = sliderClass
        this.swiperContainerElement = document.querySelector(this.sliderClass)
        this.suppliedSliderOptions  = sliderOptions

        /**
         *
         */

        if (! this.swiperContainerElement) 
            return

        /**
         * 
         */

        this.configureSlider()

    }

    /**
     * getSwiper.
     */

    getSwiper() {
        return this.instance
    }

    /**
     * configureSlider.
     */

    configureSlider() {

        /**
         * 
         */

        // let col : HTMLElement = document.querySelector(this.containerClass + ' .col-role')
        // let currentPageElement : HTMLElement = col.querySelector('.current')
        // let totalPageElement : HTMLElement   = col.querySelector('.total')

        let nextElement : HTMLElement = this.swiperContainerElement.parentElement.parentElement.querySelector('.next')
        let prevElement : HTMLElement = this.swiperContainerElement.parentElement.parentElement.querySelector('.prev')

        /**
         * 
         */

        let arrowPrevious : HTMLElement = this.swiperContainerElement.parentElement.parentElement.querySelector('.arrow-previous')
        let arrowNext : HTMLElement = this.swiperContainerElement.parentElement.parentElement.querySelector('.arrow-next')

        /**
         *
         */

        let sliderOptionsDefault : Object = {

            /** 
             *
             */

            slidesPerView: 1,

            /**
             *
             */

            autoHeight: false,

            /**
             *
             */

            loop: true,

            /**
             *
             */

            spaceBetween: 30,

            /**
             *
             */

            on: {

                /**
                 * init.
                 */

                afterInit: () => {

                    /** 
                     *
                     */

                },

                /**
                 * beforeInit.
                 */

                beforeInit: () => {

                    // /**
                    //  *
                    //  */

                    // totalPageElement.innerHTML = '0' + col.querySelectorAll('.swiper-slide').length.toString()

                },

                /**
                 * realIndexChange.
                 */

                realIndexChange: () => {

                    // /**
                    //  *
                    //  */

                    // if (! this.jobSwiperInstance) 
                    //     return

                    // /**
                    //  * 
                    //  */

                    // currentPageElement.innerHTML = "0" + (this.jobSwiperInstance.realIndex + 1).toString()

                },

                /**
                 * slideChange.
                 */

                slideChange: () => {

                    /**
                     * 
                     */

                }
                
            }
        }

        /**
         *
         */

        let options : Object = merge( sliderOptionsDefault, this.suppliedSliderOptions )
        
        /**
         * 
         */         

        this.instance = new Swiper(this.swiperContainerElement, options)

        /**
         *
         */

        if (nextElement) {

            /**
            * Add event listener to next element.
            */

            nextElement.addEventListener('click', (e) => {
                this.instance.slideNext()
                e.preventDefault()
            })

        }

        /**
         *
         */
        
        if (prevElement) {

            /**
             * Add event listener to prev element.
             */
    
            prevElement.addEventListener('click', (e) => {
                this.instance.slidePrev()
                e.preventDefault()
    
            })

        }

        /**
         * arrowPrevious.
         */

        if (arrowPrevious) {

            /**
            * Add event listener to prev element.
            */

            arrowPrevious.addEventListener('click', (e) => {
                this.instance.slidePrev()
                e.preventDefault()

            })

        }

        /**
         * arrowNext.
         */

        if (arrowNext) {

            /**
            * Add event listener to next element.
            */

            arrowNext.addEventListener('click', (e) => {
                this.instance.slideNext()
                e.preventDefault()
            })

        }

    }

}
