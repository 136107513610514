/**
 *
 */

import { App, createApp, defineComponent } from 'vue'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

/**
 * NavBar.
 */

export class NavBar {

    /**
     *
     */

    private navOpen             : boolean
    private navOpeningOrClosing : boolean
    private trigger             : HTMLElement  
    private navWrapper          : HTMLElement
    
    /**
     * constructor.
     */

    constructor() {

        /**
         *
         */

    }

    /**
     * start.
     */

    start(): void {

        /**
         *
         */

        this.navOpen = false

        /**
         *
         */

        this.trigger    = document.querySelector('.nav-trigger')
        this.navWrapper = document.querySelector('.nav-wrapper')

        /**
         *
         */

        this.configureNavBarTrigger()

        /**
         *
         */

        jQuery(document).ready(() => {

            /**
             *
             */

        })
        
    }

    /**
     * configureNavBarTrigger.
     */

    configureNavBarTrigger() {

        /**
         *
         */

        this.trigger.addEventListener( 'click', () => {

            /**
             *
             */

            let heroAreaA = document.querySelector('.common-panel-herotypea-wrapper')
            let contactUs = document.querySelector('.common-panel-contactus-wrapper')

            /**
             *
             */

            if (this.navOpen == false) {

                /**
                 *
                 */

                if (window.location.pathname == '/') {

                    /**
                     *
                     */

                    window.location.href = '/contents/'


                } else {

                    /**
                     *
                     */

                    this.showNav()
                    this.trigger.classList.add( 'open' )
                    this.navOpen = true

                    /**
                     *
                     */

                    if (heroAreaA) heroAreaA.classList.add( 'apply-blur' )
                    if (contactUs) contactUs.classList.add( 'apply-blur' )

                    /**
                     *
                     */

                    setTimeout(() => {

                        /**
                         *
                         */

                        window.scrollTo({
                            top: 0
                        })

                    }, 50)

                    /**
                     *
                     */

                    let target = document.querySelector('.nav-wrapper .content')
                    disableBodyScroll( target )

                }

            } else {

                /**
                 *
                 */

                this.hideNav()
                this.trigger.classList.remove( 'open' )    
                this.navOpen = false

                /**
                 *
                 */

                if (heroAreaA) heroAreaA.classList.remove( 'apply-blur' )
                if (contactUs) contactUs.classList.remove( 'apply-blur' )

                /**
                 *
                 */

                let target = document.querySelector('.nav-wrapper .content')
                enableBodyScroll( target )

            }

        })

    }

    /**
     * showNav.
     */

    showNav() {

        /**
         *
         */

        this.navWrapper.classList.add( 'show' )

    }

    /**
     * hideNav.
     */

    hideNav() {

        /**
         *
         */

        this.navWrapper.classList.remove( 'show' )

    }
    
}
